export const links = {
  HOMEPAGE: '/',
  EXPLORE: '/packages',
  DEPLOY: '/deploy',
  QUEUETXS: '/deploy/queue',
  QUEUEFROMGITOPS: '/deploy/gitops',
  SETTINGS: '/settings',
  LEARN: '/learn',
  GUIDES: '/learn/guides',
  GETSTARTED: '/learn/guides/get-started',
  BUILD: '/learn/guides/build',
  ROUTER: '/learn/guides/router',
  DEBUG: '/learn/guides/debug',
  CLI: '/learn/cli',
  CANNONFILE: '/learn/cannonfile',
  IPFS_DOWNLOAD: '/ipfs',
  IPFS_UPLOAD: '/ipfs/upload',
  DOCS_CLI_RUN: '/learn/cli#run',
  DOCS_CANNONFILE_PROVISION: '/learn/cannonfile#clone',
};
